.heroBgImage {
  object-position: top 25px right 2%;
  transform: scale(1.2);
  overflow: hidden;
}

@media (min-width: 390px) {
  .heroBgImage {
    object-position: top 25px right 5%;
  }
}

/* @media (min-width: 414px) {
  .heroBgImage {
    object-position: top 25px right 5%;
  }
} */

@media (min-width: 767px) {
  .heroBgImage {
    object-position: 76% center;
  }
}

@media (min-width: 1024px) {
  .heroBgImage {
    object-position: 80% center;
  }
}
